<template>
  <div class="categorie-setting" v-if="selectedCategorie != null">
    <div class="header">
      <div class="titleG">
        <div @click="handelCancel($event)" class="fleche" title="retour">
          <font-awesome-icon class="" icon="arrow-circle-left" />
        </div>
        <div class="titre">
          <h3 class="font ml-1">
            Gestion des sous-catégories
          </h3>
          <p class="label-category">
            <span class="margin-color">Catégorie:</span>
            <span class="color">
              {{
                selectedCategorie && selectedCategorie.name
                  ? selectedCategorie.name
                  : ''
              }}</span
            >
          </p>
          <div class="message">
            <div v-if="getCategorieLoading" class="three-dots-loading mt-2">
              Chargement en cours
            </div>
            <div v-if="getCategorieError" class="error">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <b-button
          class="moveSousCategorie"
          size="sm"
          variant="primary"
          @click="showModalMoveSousCategories"
          v-if="
            $route &&
              $route.name == 'categorieUpdatePieceJointe' &&
              getCategoriesPieceJointeFile &&
              getCategoriesPieceJointeFile.length > 1 &&
              computedSubcategoriesExist
          "
        >
          <font-awesome-icon class="" icon="arrows-alt" />
          Déplacer sous-catégories
        </b-button>
        <b-button
          class="addSousCategorie ml-2"
          size="sm"
          variant="success"
          v-b-modal.addSubCategorieModal
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter sous-catégorie
        </b-button>
      </div>
    </div>
    <hr class="line-subcategory" />
    <!-- <div class="ml-2"><h4>Sous-catégories</h4></div> -->
    <div
      class="card-scene"
      ref="cardSceneCategory"
      :class="{
        'not-height':
          !selectedCategorie.subCategorie ||
          !selectedCategorie.subCategorie.length,
        disabled: getCategorieLoading
      }"
    >
      <Container
        :drop-placeholder="dropPlaceholderOptions"
        :lock-axis="'y'"
        @drop="onColumnDrop"
        drag-handle-selector=".column-drag-container "
        :animation-duration="300"
      >
        <Draggable
          v-for="sousCategorie in selectedCategorie.subCategorie"
          :key="sousCategorie.id"
        >
          <div class="column-drag-container ">
            <div class="card-column-header">
              <div
                class="column-drag-handle"
                :class="{
                  'handle-pj':
                    $route && $route.name == 'categorieUpdatePieceJointe'
                }"
              >
                ☰
              </div>
              <div
                class="input-title-sous-categorie"
                :class="{
                  file: $route && $route.name == 'categorieUpdatePieceJointe'
                }"
              >
                <div class="body-drag">
                  <input
                    class="input-focus"
                    placeholder="Nom de sous catégorie"
                    @change="updateNameSousCategorie($event, sousCategorie)"
                    title="Modifier le nom de sous catégorie"
                    :value="sousCategorie.name"
                  />
                  <div
                    class="ClassType"
                    v-if="$route && $route.name != 'categorieUpdatePieceJointe'"
                  >
                    <span class="span-title">Type</span>
                    <b-form-select
                      @change="handleTypeChange($event, sousCategorie)"
                      :options="getProjectsTypes"
                      size="sm"
                      v-model="sousCategorie.selectedType"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div
                    class="ClassSousType"
                    v-if="$route && $route.name != 'categorieUpdatePieceJointe'"
                  >
                    <span class="span-title">Définit les Sous-types</span>
                    <multiselect
                      class="multiselectC"
                      @input="handleSousTypeChange(sousCategorie)"
                      v-model="sousCategorie.subtype.data"
                      :options="
                        [
                          { name: '', id: null },
                          ...sousCategorie.listSubType
                        ] || []
                      "
                      placeholder="Rechercher"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :showLabels="false"
                      label="name"
                      track-by="name"
                    >
                      <span slot="noResult">Aucune option trouvée.</span>
                      <span slot="noOptions">Aucune option trouvée.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="action-drag">
                  <router-link
                    v-if="$route && $route.name == 'categorieUpdateContact'"
                    :to="
                      '/setting/categorie-contact/' +
                        selectedCategorie.id +
                        '/sous-categorie/' +
                        sousCategorie.id
                    "
                    class="user-link"
                  >
                    Configuration des champs
                  </router-link>
                  <router-link
                    v-if="$route && $route.name == 'categorieUpdateProjet'"
                    :to="
                      '/setting/categorie-projet/' +
                        selectedCategorie.id +
                        '/sous-categorie/' +
                        sousCategorie.id
                    "
                    class="user-link"
                  >
                    Configuration des champs
                  </router-link>
                  <div class="icon-sous-categorie">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="tryDeleteSousCategorie(sousCategorie)"
                      title="Supprimer une sous-catégorie "
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
    <div
      v-if="
        !selectedCategorie.subCategorie ||
          !selectedCategorie.subCategorie.length
      "
      class="style-aucun-category"
    >
      Aucun sous catégorie
    </div>
    <!-- Add Sous_Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      id="addSubCategorieModal"
      ref="addSubCategorieModal"
      title="Ajouter une sous-catégorie"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="addBlockSousCategorie">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="SousCategorieToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Type "
          label-for="categorie-select"
          v-if="$route && $route.name != 'categorieUpdatePieceJointe'"
        >
          <b-form-select
            id="categorie-select"
            @change="handleTypeChange($event)"
            :options="getProjectsTypes"
            value-field="id"
            text-field="name"
            v-model="SousCategorieToAdd.type"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Sous-type"
          label-for="categorie-select"
          v-if="$route && $route.name != 'categorieUpdatePieceJointe'"
        >
          <multiselect
            class="multiselectS"
            v-model="SousCategorieToAdd.subtype_id"
            :options="getListSubType"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="name"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </multiselect>
        </b-form-group>
        <div class="message">
          <div v-if="getCategorieLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getCategorieError" class="error">
            <ul v-if="Array.isArray(getCategorieError)">
              <li v-for="(e, index) in getCategorieError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCategorieError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addSubCategorieModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="getCategorieLoading"
          >
            Valider
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- Dellete SubCategorie -->
    <b-modal
      no-close-on-backdrop
      ref="deleteSousCategorieModal"
      id="deleteSousCategorieModal"
      title="Supprimer une sous-catégorie"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p class="mb-3">
        Êtes-vous certain de vouloir supprimer la sous-catégorie
        <strong>
          {{ sousCategorieToDelete ? sousCategorieToDelete.name : '' }}
        </strong>
        ?
      </p>
      <div class="message">
        <div v-if="getCategorieLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getCategorieError" class="error">
          <ul v-if="Array.isArray(getCategorieError)">
            <li v-for="(e, index) in getCategorieError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getCategorieError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('deleteSousCategorieModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="deleteSousCategorie">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- deplacement subcategory -->
    <!-- Add Sous_Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      id="moveSubCategorieModal"
      ref="moveSubCategorieModal"
      :title="
        `Déplacer des sous-catégories dans cette catégorie : ${
          selectedCategorie && selectedCategorie.name
            ? selectedCategorie.name
            : ''
        }`
      "
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-form-group label="Sous-catégories">
        <multiselect
          v-model="selectedMoveSubCategory"
          :options="subCategoryOfCategoryOptions || []"
          placeholder="Rechercher sous-catégories"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          :allow-empty="true"
          label="text"
          track-by="value"
          :preselect-first="false"
          :loading="getCategorieLoading"
          group-values="subcategorie"
          group-label="categorie"
          :group-select="true"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          selectGroupLabel=""
          deselectGroupLabel=""
        >
          <span slot="noResult">Aucun option trouvé.</span>
          <span slot="noOptions">Aucun option trouvé.</span>
        </multiselect>
      </b-form-group>
      <div class="message">
        <div v-if="getCategorieLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getCategorieError" class="error">
          <ul v-if="Array.isArray(getCategorieError)">
            <li v-for="(e, index) in getCategorieError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getCategorieError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('moveSubCategorieModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button
          variant="success"
          type="submit"
          :disabled="
            getCategorieLoading ||
              !selectedMoveSubCategory ||
              !selectedMoveSubCategory.length
          "
          @click="MoveSubCategories"
        >
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedCategorie: null,
      SousCategorieToAdd: {
        name: null,
        subtype_id: [],
        type: null
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      sousCategorieToDelete: null,
      selectedMoveSubCategory: []
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchFrojectSubType',
      'addSubCategorie',
      'deleteSubCategorie',
      'updateSubCategorie',
      'resetErrorCategorie',
      'fetchCategoriesSubCategorie',
      'fetchOneCategorie',
      'fetchOneCategoriePJ',
      'addSubCategoriesFilesPJ',
      'deleteSubCategoriePJ',
      'updateSubCategoriesFilesPJ',
      'moveSubCategoriesFilesPJ',
      'fetchCategoriesPieceJointeFile'
    ]),
    async MoveSubCategories() {
      if (this.selectedMoveSubCategory && this.selectedMoveSubCategory.length) {
        const response = await this.moveSubCategoriesFilesPJ({
          categoriePJ: this.selectedCategorie,
          data: {
            categories:
              this.selectedMoveSubCategory &&
              this.selectedMoveSubCategory.length
                ? this.selectedMoveSubCategory.map(item => item.value)
                : []
          }
        })
        if (response) {
          // scroll when add new stage
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.hideModal('moveSubCategorieModal')
            // Refresh liste des sous categories et categories
            this.fetchCategoriesPieceJointeFile({ loading: true })
          })
        }
      }
    },
    showModalMoveSousCategories() {
      if (
        !this.getCategoriesPieceJointeFile ||
        !this.getCategoriesPieceJointeFile.length
      ) {
        this.fetchCategoriesPieceJointeFile({ loading: true })
      }
      this.$refs['moveSubCategorieModal'].show()
    },
    handleTypeChange($event, sousCategorie) {
      this.fetchFrojectSubType({ id: $event, sousCategorie })
    },
    //add subCategorie
    async addBlockSousCategorie() {
      if (
        this.$route &&
        (this.$route.name == 'categorieUpdateContact' ||
          this.$route.name == 'categorieUpdateProjet')
      ) {
        let count = this.selectedCategorie.subCategorie.length
        const sousCategorie = {
          name: this.SousCategorieToAdd.name,
          order: count
        }
        if (this.SousCategorieToAdd.subtype_id) {
          sousCategorie.subtype_id = this.SousCategorieToAdd.subtype_id.id
        }
        const response = await this.addSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: sousCategorie
        })
        if (response) {
          // scroll when add new stage
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.hideModal('addSubCategorieModal')
          })
        }
      } else if (
        this.$route &&
        this.$route.name == 'categorieUpdatePieceJointe'
      ) {
        const sousCategorie = {
          name: this.SousCategorieToAdd.name,
          parent_id: this.selectedCategorie.id
        }
        const response = await this.addSubCategoriesFilesPJ({
          categoriesFichiers: this.selectedCategorie,
          sousCategorie: sousCategorie
        })
        if (response) {
          // scroll when add new stage
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.hideModal('addSubCategorieModal')
          })
        }
      }
    },
    //updatednname SubCategorie
    updateNameSousCategorie(e, sousCategorie) {
      if (
        this.$route &&
        (this.$route.name == 'categorieUpdateContact' ||
          this.$route.name == 'categorieUpdateProjet')
      ) {
        this.updateSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: sousCategorie,
          data: { name: e.target.value }
        })
      } else if (
        this.$route &&
        this.$route.name == 'categorieUpdatePieceJointe'
      ) {
        this.updateSubCategoriesFilesPJ({
          sousCategorie: sousCategorie,
          data: { name: e.target.value, parent_id: this.selectedCategorie.id }
        })
      }
    },
    //show modal delete SubCategorie
    tryDeleteSousCategorie(sousCategorie) {
      this.sousCategorieToDelete = sousCategorie
      this.$refs['deleteSousCategorieModal'].show()
    },
    //delete subCategorie
    async deleteSousCategorie() {
      let response = false
      if (
        this.$route &&
        (this.$route.name == 'categorieUpdateContact' ||
          this.$route.name == 'categorieUpdateProjet')
      ) {
        response = await this.deleteSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: this.sousCategorieToDelete
        })
      } else if (
        this.$route &&
        this.$route.name == 'categorieUpdatePieceJointe'
      ) {
        response = await this.deleteSubCategoriePJ({
          categorie: this.selectedCategorie,
          subCategorieToDelete: this.sousCategorieToDelete
        })
      }
      if (response) {
        this.hideModal('deleteSousCategorieModal')
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },

    resetModal() {
      this.sousCategorieToDelete = null
      this.SousCategorieToAdd = {
        name: null,
        subtype_id: [],
        type: null
      }
      this.resetErrorCategorie()
      this.selectedMoveSubCategory = []
    },

    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.selectedCategorie.subCategorie = this.applyDrag(
        this.selectedCategorie.subCategorie,
        dropResult
      )
      if (
        this.$route &&
        (this.$route.name == 'categorieUpdateContact' ||
          this.$route.name == 'categorieUpdateProjet')
      ) {
        this.updateSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: this.selectedCategorie.subCategorie[addedIndex],
          data: {
            order: addedIndex
          }
        })
      } else if (
        this.$route &&
        this.$route.name == 'categorieUpdatePieceJointe'
      ) {
        this.updateSubCategoriesFilesPJ({
          sousCategorie: this.selectedCategorie.subCategorie[addedIndex],
          data: {
            order: addedIndex,
            parent_id: this.selectedCategorie.id
          }
        })
      }
    },
    //retour
    handelCancel() {
      if (this.$route && this.$route.name == 'categorieUpdateContact') {
        this.$router.push('/setting/categorie-contact')
      }
      if (this.$route && this.$route.name == 'categorieUpdateProjet') {
        this.$router.push('/setting/categorie-projet')
      }
      if (this.$route && this.$route.name == 'categorieUpdatePieceJointe') {
        this.$router.push('/setting/categorie-piece-jointe')
      }
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    //update SubType
    handleSousTypeChange(sousCategorie) {
      this.updateSubCategorie({
        categorie: this.selectedCategorie,
        sousCategorie: sousCategorie,
        data: {
          subtype_id: sousCategorie.subtype.data.id
        }
      })
    },
    //fetch SubType for SubCategorie
    async fetchListSousType() {
      for (let i = 0; i < this.selectedCategorie.subCategorie.length; i++) {
        if (
          this.selectedCategorie.subCategorie[i].subtype &&
          this.selectedCategorie.subCategorie[i].subtype.data &&
          this.selectedCategorie.subCategorie[i].subtype.data.type &&
          this.selectedCategorie.subCategorie[i].subtype.data.type.data &&
          this.selectedCategorie.subCategorie[i].subtype.data.type.data.id
        ) {
          await this.fetchFrojectSubType({
            id: this.selectedCategorie.subCategorie[i].subtype.data.type.data
              .id,
            sousCategorie: this.selectedCategorie.subCategorie[i],
            categorie: this.selectedCategorie
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCategoriesCategorie',
      'getCategorieLoading',
      'getCategorieError',
      'getProjectsTypes',
      'getListSubType',
      'getSubCategoriesPieceJointeFile',
      'getCategoriesPieceJointeFile'
    ]),
    computedSubcategoriesExist: function() {
      if (
        this.subCategoryOfCategoryOptions &&
        this.subCategoryOfCategoryOptions.length
      ) {
        for (
          let index = 0;
          index < this.subCategoryOfCategoryOptions.length;
          index++
        ) {
          if (
            this.subCategoryOfCategoryOptions[index].subcategorie &&
            this.subCategoryOfCategoryOptions[index].subcategorie.length
          ) {
            return true
          }
        }
      }
      return false
    },
    subCategoryOfCategoryOptions: function() {
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length &&
        this.$route &&
        this.$route.params &&
        this.$route.params.id
      ) {
        return this.getCategoriesPieceJointeFile
          .filter(item => item.id != parseInt(this.$route.params.id))
          .map(categorie => {
            return {
              categorie: categorie.name,
              subcategorie: categorie.subCategorie.map(subCategory => {
                return {
                  text: subCategory.name,
                  value: subCategory.id
                }
              })
            }
          })
      }
      return []
    }
  },
  async mounted() {
    if (this.$route && this.$route.name == 'categorieUpdatePieceJointe') {
      await this.fetchOneCategoriePJ(this.$route.params.id)
      if (!this.getSubCategoriesPieceJointeFile) {
        this.$router.push('/setting/categorie-piece-jointe')
      }
      if (this.getSubCategoriesPieceJointeFile) {
        this.selectedCategorie = this.getSubCategoriesPieceJointeFile
      }
      // refresh btn deplacer
      if (
        !this.subCategoryOfCategoryOptions ||
        !this.subCategoryOfCategoryOptions.length
      ) {
        this.fetchCategoriesPieceJointeFile({ loading: true })
      }
    } else {
      await this.fetchOneCategorie(this.$route.params.id)
      if (!this.getCategoriesCategorie) {
        if (this.$route && this.$route.name == 'categorieUpdateContact') {
          this.$router.replace('/setting/categorie-contact').catch(() => {})
        }
        if (this.$route && this.$route.name == 'categorieUpdateProjet') {
          this.$router.replace('/setting/categorie-projet').catch(() => {})
        }
      }

      this.selectedCategorie = this.getCategoriesCategorie
      this.fetchCategoriesSubCategorie({
        selectedCategorie: this.selectedCategorie
      })
      await this.fetchProjetsTypes({
        exclude: ['fields']
      })
      setTimeout(() => {
        this.fetchListSousType()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.categorie-setting {
  height: 100%;
  .header {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    // white-space: nowrap;
    .titleG {
      display: flex;
      // align-items: center;
    }
    .color {
      color: #007bff;
    }
    .margin-color {
      margin-left: 4px;
    }
    .titre {
      margin-left: 10px;
      margin-bottom: 6px;
      .line-responsive {
        display: none;
      }
      .label-category {
        font-weight: bold;
      }
    }
  }
  .button {
    display: flex;
    align-items: center;
    .addSousCategorie,
    .moveSousCategorie {
      font-size: 13px;
    }
  }
  .style-aucun-category {
    padding: 15px;
    border: 1px solid #bfcdd2;
    text-align: center;
    color: #2dabe7;
  }
  .select-color {
    margin-right: 5px;
  }
  .fleche {
    margin-top: 8px;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #6c757d;
    }
  }
  .card-scene {
    height: calc(100% - 180px);
    &.not-height {
      height: 0px;
    }
    &.disabled {
      pointer-events: none;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    color: #7b8785;
    background-color: transparent;
    border-color: transparent;
  }
  @media screen and (max-width: 280px) {
    .header {
      display: block;
      .titre {
        .font {
          font-size: 15px;
          font-weight: bold;
        }
        .line-responsive {
          display: block;
        }
      }
    }
    .input-focus {
      width: 100%;
    }
    .column-drag-handle {
      top: 9px;
    }
    .ClassType {
      margin-left: -18px;
    }
    .ClassSousType {
      margin-left: -18px;
      .span-title {
        font-size: 13px;
        padding-left: 0px;
      }
      .multiselect {
        min-height: 29px;
        width: 147px;
        margin-left: -7px;
      }
    }
    .icon-sous-categorie {
      margin-left: -16px;
    }
    .input-title-sous-categorie {
      display: block;
      .body-drag {
        display: block;
      }
    }
    .smooth-dnd-container {
      min-width: 100%;
    }
  }
  @media screen and (max-width: 580px) {
    .header {
      display: block;
      .titre {
        .font {
          font-size: 15px;
          font-weight: bold;
        }
        .line-responsive {
          display: block;
        }
      }
    }
    .input-focus {
      width: 100%;
    }
    .column-drag-handle {
      top: 9px;
    }
    .ClassSousType {
      margin-bottom: 8px;
      .span-title {
        font-size: 13px;
        padding-left: 0px;
      }
      .multiselect {
        min-height: 29px;
        width: 147px;
        margin-left: -7px;
      }
    }
    .ClassType {
      margin-bottom: 8px;
      .span-title {
        padding-left: 0px;
      }
    }
    .ClassType,
    .ClassSousType {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .icon-sous-categorie {
      margin-left: 32px;
    }
    .input-title-sous-categorie {
      &.file {
        display: flex;
      }
      display: block;
      .body-drag {
        display: block;
      }
    }
    .action-drag {
      margin-left: -12px;
    }
    .smooth-dnd-container {
      min-width: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    .header {
      display: block;
      .titre {
        .font {
          font-size: 15px;
          font-weight: bold;
        }
        .line-responsive {
          display: block;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.column-drag-handle,
.ss-column-drag-handle {
  position: absolute;
  top: 24px;
  left: 6px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  &.handle-pj {
    top: 11px;
  }
}

// .ss-column-drag-handle {
//   top: 5px;
//   left: 3px;
// }

.input-title-sous-categorie {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  align-items: center;
  .body-drag {
    display: flex;
    -webkit-box-align: center;
    .input-focus {
      &:focus {
        background-color: #f8f9fa;
      }
    }
    input {
      color: black;
      overflow: visible;
      background-color: transparent;
      border: 0px;
      font-size: 15px;
      text-overflow: ellipsis;
    }
    .custom-select-sm {
      width: 139px;
    }
  }
  .action-drag {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-sous-categorie {
    align-self: center;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.icon-sous-categorie {
  .btn-left {
    color: #495057ab;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-right: 3px;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #495057ab;
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.075);
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
}
.smooth-dnd-container.horizontal {
  display: flex;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  position: relative;
  overflow: visible;
  margin-top: 5px;
  border: 1px solid #6c757d52;
  padding: 4px;
  background-color: #b0e6f252;
  width: 100%;
  border-radius: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.smooth-dnd-container {
  margin: 3px;
}
.smooth-dnd-container {
  position: relative;
  min-height: 30px;
  min-width: 255px;
  padding-bottom: 16px;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  border: 1px solid #6c757d4d;
  margin-right: 8px;
  background-color: #ebebeb5c;
  border-radius: 4px;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 7px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
  width: 77px;
}
</style>
<style lang="scss" scoped>
.message {
  .error {
    ul {
      margin-top: 9px;
      margin-bottom: 0px;
    }
  }
}
.slide-toggle {
  display: none;
}
.slidemenu {
  border: 0px;
  background-color: transparent;
  overflow: hidden;
}

.slidemenu label {
  width: 25%;
  text-align: center;
  display: block;
  float: left;
  color: #333;
  opacity: 4.2;
}

.slidemenu label:hover {
  cursor: pointer;
  color: #666;
}

.slidemenu label span {
  display: block;
  padding: 10px;
}

.slidemenu label .icon {
  font-size: 20px;
  border: solid 2px #333;
  text-align: center;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
  line-height: 50px;
}
.multiselectS {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  margin-bottom: 3em;
}
</style>
<style lang="scss">
#moveSubCategorieModal {
  .modal-content {
    width: 133%;
  }
  .multiselect__option--group {
    background: #6c757d;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    &:hover {
      background: #35495e;
      color: #fff;
    }
  }
}
.multiselectC {
  min-height: 29px;
  width: 200px;
  margin-left: 5px;
  .multiselect__select {
    height: 32px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
  }
  .multiselect__tags {
    min-height: 11px;

    padding: 3px 40px 0 8px;
  }
  .multiselect__placeholder {
    margin-bottom: 3px;
  }
}
.ClassType {
  display: grid;
  .span-title {
    padding-left: 10px;
    font-size: 13px;
  }
}
.ClassSousType {
  display: inline;
  .span-title {
    padding-left: 10px;
    font-size: 13px;
  }
}
</style>
